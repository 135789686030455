import React from 'react'
import { Head, Footer } from '~/components'
import { useMediaQuery } from 'react-responsive'
import * as st from '~/assets/styl/TermosPolitica.module.styl'
import img from '~/assets/img/img-privacidade.jpg'
import imgMobile from '~/assets/img/img-privacidade-mobile.jpg'

const PrivacityPolitic = () => {
  const mobile = !useMediaQuery({ query: '(min-width: 551px)' })

  return (
    <>
      <Head />
      <main
        className={st.core}
        style={
          mobile === true
            ? { background: `url(${imgMobile}) top center no-repeat` }
            : { background: `url(${img}) top center no-repeat` }
        }
      >
        <section className="container">
          <h1>Política de privacidade</h1>
          <h2>Objetivo</h2>
          <p>
            As Empresas Dimas estão engajadas na conscientização dos usuários de
            seu site em relação aos dados pessoais eventualmente tratados.
          </p>
          <p>
            Comprometido em respeitar a sua privacidade e prezar pela
            transparência, elaboramos a presente política de privacidade, com o
            objetivo de proporcionar um claro entendimento sobre como é
            realizado o tratamento dos seus dados pessoais em nosso site.
          </p>
          <p>
            Destaca-se que o presente instrumento informa sobre como é realizado
            o tratamento de dados pessoais coletados exclusivamente no site do
            Dimas Seguros. As demais hipóteses de tratamento de dados de
            colaboradores, fornecedores, candidatos a vagas de emprego, dentre
            outros, estão na Política de Privacidade interna das Empresas Dimas,
            a qual pode ser solicitada pelos titulares através do e-mail
            privacidade@dimas.com.br.
          </p>
          <p>
            Os dados pessoais serão tratados apenas enquanto forem necessários e
            adequados para as finalidades que embasam a coleta, bem como o
            tratamento sempre irá se enquadrar à uma das bases legais previstas
            pela Lei Geral de Proteção de Dados.
          </p>
          <p>
            As Empresas Dimas requerem a todos os usuários de seu site que façam
            a leitura atenta deste documento antes de submeter seus dados nos
            formulários de cadastro. Seu cadastro significa que você compreendeu
            como coletamos e tratamos seus dados pessoais.
          </p>
          <p>
            Para mais informações acerca do tratamento de seus dados pessoais,
            entre em contato através do e-mail privacidade@dimas.com.br.
          </p>
          <h2>Definições</h2>
          <ul>
            <li>
              <b>Titular:</b> pessoa natural a quem se referem os dados pessoais
              que são objeto de tratamento;
            </li>
            <li>
              <b>Dado pessoal:</b> informação relacionada a pessoa natural
              identificada ou identificável, como por exemplo nome, e-mail,
              telefone, dentre outros;
            </li>
            <li>
              <b>Dado pessoal sensível</b> dado pessoal sobre origem racial ou
              étnica, convicção religiosa, opinião política, filiação a
              sindicato ou a organização de caráter religioso, filosófico ou
              político, dado referente à saúde ou à vida sexual, dado genético
              ou biométrico, quando vinculado a uma pessoa natural;
            </li>
            <li>
              <b>Tratamento:</b> toda operação realizada com dados pessoais,
              como as que se referem a coleta, produção, recepção,
              classificação, utilização, acesso, reprodução, transmissão,
              distribuição, processamento, arquivamento, armazenamento,
              eliminação, avaliação ou controle da informação, modificação,
              comunicação, transferência, difusão ou extração;
            </li>
            <li>
              <b>Controlador:</b> pessoa natural ou jurídica, de direito público
              ou privado, a quem competem as decisões referentes ao tratamento
              de dados pessoais;
            </li>
            <li>
              <b>Encarregado:</b> pessoa indicada pelo controlador e operador
              para atuar como canal de comunicação entre o controlador, os
              titulares dos dados e a Autoridade Nacional de Proteção de Dados
              (ANPD);
            </li>
            <li>
              <b>Operador:</b> pessoa natural ou jurídica, de direito público ou
              privado, que realiza o tratamento de dados pessoais em nome do
              controlador;
            </li>
            <li>
              <b>Transferência internacional de dados:</b> transferência de
              dados pessoais para país estrangeiro ou organismo internacional do
              qual o país seja membro;
            </li>
            <li>
              <b>Autoridade Nacional de Proteção de Dados (ANPD):</b> órgão da
              administração pública federal, integrante da Presidência da
              República, que possui, dentre outras funções previstas pela Lei n.
              13.709/2018, a de zelar pela proteção dos dados pessoais, elaborar
              diretrizes para a Política Nacional de Proteção de Dados Pessoais
              e da Privacidade, fiscalizar e aplicar sanções em caso de
              tratamento de dados realizado em descumprimento à legislação,
              mediante processo administrativo que assegure o contraditório, a
              ampla defesa e o direito de recurso e promover na população o
              conhecimento das normas e das políticas públicas sobre proteção de
              dados pessoais e das medidas de segurança.
            </li>
            <li>
              <b>Uso compartilhado de dados:</b> comunicação, difusão,
              transferência internacional, interconexão de dados pessoais ou
              tratamento compartilhado de bancos de dados pessoais por órgãos e
              entidades públicos no cumprimento de suas competências legais, ou
              entre esses e entes privados, reciprocamente, com autorização
              específica, para uma ou mais modalidades de tratamento permitidas
              por esses entes públicos, ou entre entes privados.
            </li>
          </ul>
          <h2>
            Princípios aplicáveis ao tratamento de dados realizado pela Dimas
            Seguros
          </h2>
          <p>
            As Empresas Dimas, no papel de Controlador, asseguram o integral
            cumprimento do disposto pelo art. 6º, da Lei Geral de Proteção de
            Dados, o qual estipula os princípios que regem as operações de
            tratamento de dados pessoais, quais sejam:
          </p>
          <ul>
            <li>
              <b>Finalidade:</b> realização do tratamento para propósitos
              legítimos, específicos, explícitos e informados ao titular, sem
              possibilidade de tratamento posterior de forma incompatível com
              essas finalidades;
            </li>
            <li>
              <b>Adequação:</b> compatibilidade do tratamento com as finalidades
              informadas ao titular, de acordo com o contexto do tratamento;
            </li>
            <li>
              <b>Necessidade:</b> limitação do tratamento ao mínimo necessário
              para a realização de suas finalidades, com abrangência dos dados
              pertinentes, proporcionais e não excessivos em relação às
              finalidades do tratamento de dados;
            </li>
            <li>
              <b>Livre acesso:</b> garantia, aos titulares, de consulta
              facilitada e gratuita sobre a forma e a duração do tratamento, bem
              como sobre a integralidade de seus dados pessoais;
            </li>
            <li>
              <b>Qualidade dos dados:</b> garantia, aos titulares, de exatidão,
              clareza, relevância e atualização dos dados, de acordo com a
              necessidade e para o cumprimento da finalidade de seu tratamento;
            </li>
            <li>
              <b>Transparência:</b> garantia, aos titulares, de informações
              claras, precisas e facilmente acessíveis sobre a realização do
              tratamento e os respectivos agentes de tratamento, observados os
              segredos comercial e industrial;
            </li>
            <li>
              <b>Segurança:</b> utilização de medidas técnicas e administrativas
              aptas a proteger os dados pessoais de acessos não autorizados e de
              situações acidentais ou ilícitas de destruição, perda, alteração,
              comunicação ou difusão;
            </li>
            <li>
              <b>Prevenção:</b> adoção de medidas para prevenir a ocorrência de
              danos em virtude do tratamento de dados pessoais;
            </li>
            <li>
              <b>Não discriminação:</b>
              impossibilidade de realização do tratamento para fins
              discriminatórios ilícitos ou abusivos;
            </li>
            <li>
              <b>Responsabilização e prestação de contas:</b> demonstração, pelo
              agente, da adoção de medidas eficazes e capazes de comprovar a
              observância e o cumprimento das normas de proteção de dados
              pessoais e, inclusive, da eficácia dessas medidas.
            </li>
          </ul>
          <h2>Hipóteses em que é permitido o tratamento de dados</h2>
          <h3>
            A LGPD prevê, em seu art. 7º, as hipóteses em que o tratamento de
            dados pessoais é permitido. Estas hipóteses são denominadas “bases
            legais” e elas podem ser utilizadas tanto individualmente, quanto de
            maneira conjunta, para respaldar o tratamento de dados. Importante
            esclarecer que as bases legais para o tratamento de dados são:
          </h3>
          <ul>
            <li>O consentimento;</li>
            <li>
              O cumprimento de obrigação legal ou regulatória pelo controlador;
            </li>
            <li>
              O tratamento e uso compartilhado de dados realizado pela
              administração pública necessários à execução de políticas públicas
              previstas em leis e regulamentos ou respaldadas em contratos,
              convênios ou instrumentos congêneres;
            </li>
            <li>
              A realização de estudos por órgão de pesquisa, garantida, sempre
              que possível, a anonimização dos dados pessoais;
            </li>
            <li>
              A execução de contrato ou de procedimentos preliminares
              relacionados a contrato do qual seja parte o titular, a pedido do
              titular dos dados;
            </li>
            <li>
              O exercício regular de direitos em processo judicial,
              administrativo ou arbitral;
            </li>
            <li>
              A proteção da vida ou da incolumidade física do titular ou de
              terceiro.
            </li>
            <li>Dados coletados, a forma e a finalidade da coleta</li>
          </ul>
          <h2>Dados pessoais</h2>
          <h4>
            As Empresas Dimas realizarão o tratamento dos dados inseridos no
            site https://www.dimasconstrucoes.com.br. Estes dados serão
            fornecidos diretamente por você através dos formulários “Contato”,
            “Assine Nossa Newsletter”, “Trabalhe Conosco”, “Fale Conosco”
            “Contate-nos pelo Whatsapp”:
          </h4>
          <ul>
            <li>Nome;</li>
            <li>E-mail;</li>
            <li>Telefone;</li>
          </ul>
          <p>
            Estes dados serão coletados para cumprir as seguintes finalidades:
          </p>
          <ul>
            <li>Contatar o usuário do site por e-mail e/ou WhatsApp;</li>
            <li>
              Efetuar o cadastro do usuário nas campanhas de e-mail marketing e
              recebimento de promoções,
            </li>
            <li>
              Efetuar o cadastro do usuário nas campanhas de e-mail marketing e
              recebimento de promoções,
            </li>
            <li>Efetuar proposta para compra de veículos;</li>
            <li>Permitir o atendimento do vendedor;</li>
            <li>Direcionamento de publicidade.</li>
          </ul>
          <h2>Cookies</h2>
          <p>
            Os cookies são, em síntese, um pequeno arquivo de texto que possui
            uma etiqueta de identificação exclusiva, colocada no seu computador
            por um site. Por meio desse arquivo, podem ser armazenadas diversas
            informações, desde as páginas visitadas até os dados fornecidos
            voluntariamente ao site.
          </p>
          <p>
            As Empresas Dimas informam que também irão tratar dados fornecidos
            indiretamente pelos usuários do site, por meio de cookies enviados
            ao seu navegador, que não possibilitam a identificação do usuário e
            são necessários para algumas finalidades, quais sejam:
          </p>
          <div className={st.table}>
            <table>
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Finalidade</th>
                  <th>Tempo de Permanência</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>test_cookie</td>
                  <td>
                    Usado para verificar se o browser do usuário suporta cookies
                  </td>
                  <td>1 dia</td>
                </tr>
                <tr>
                  <td>VisitorId</td>
                  <td>
                    Preserva os estados dos usuários nas solicitações de página.
                  </td>
                  <td>2 meses</td>
                </tr>
                <tr>
                  <td>PROVIDER TYPE EXPIRY @</td>
                  <td>Não classificado</td>
                  <td>Sessão</td>
                </tr>
                <tr>
                  <td>keyval-store#keyval</td>
                  <td>
                    Utilizado para manter as preferências dos visitantes através
                    da visita e sub-páginas.
                  </td>
                  <td>Persistente</td>
                </tr>
                <tr>
                  <td>__utmz</td>
                  <td>
                    Coleta dados sobre de onde o usuário veio, qual mecanismo de
                    busca foi utilizado, qual link foi clicado e em que termo de
                    pesquisa foi usado. Usado pelo Google Analytics.
                  </td>
                  <td>179 dias</td>
                </tr>
                <tr>
                  <td>_ga</td>
                  <td>
                    Cadastra um ID único que é utilizado para gerar dados
                    estatísticos sobre como o visitante utiliza o site.
                  </td>
                  <td>2 anos</td>
                </tr>
                <tr>
                  <td>_gat</td>
                  <td>
                    Usado pelo Google Analytics para reduzir a taxa de
                    solicitação
                  </td>
                  <td>1 dia</td>
                </tr>
                <tr>
                  <td>_gd#</td>
                  <td>Não classificado</td>
                  <td>Sessão</td>
                </tr>
                <tr>
                  <td>_gid</td>
                  <td>
                    Cadastra um ID único que é utilizado para gerar dados
                    estatísticos sobre como o visitante utiliza o site.
                  </td>
                  <td>1 dia</td>
                </tr>
                <tr>
                  <td>__trf.src</td>
                  <td>
                    Registra como o usuário acessou o site para permitir o
                    pagamento de comissões de indicação aos parceiros.
                  </td>
                  <td>1 ano</td>
                </tr>
                <tr>
                  <td>_fbp</td>
                  <td>
                    Usado pelo Facebook para entregar uma série de produtos
                    publicitários, como lances em tempo real de terceiros
                    anunciantes.
                  </td>
                  <td>3 meses</td>
                </tr>
                <tr>
                  <td>_gcl_au</td>
                  <td>
                    Usado pelo Google AdSense para experimentar a eficiência da
                    publicidade em sites usando seu serviço.
                  </td>
                  <td>3 meses</td>
                </tr>
                <tr>
                  <td>ads/ga-audiences</td>
                  <td>
                    Usado pelo Google AdWords para reengajar visitantes que
                    provavelmente se converterão em clientes com base no
                    comportamento on-line do visitante nos sites.
                  </td>
                  <td>Sessão</td>
                </tr>
                <tr>
                  <td>fr</td>
                  <td>
                    Usado pelo Facebook para entregar uma série de produtos
                    publicitários, como lances em tempo real de terceiros
                    anunciantes.
                  </td>
                  <td>3 meses</td>
                </tr>
                <tr>
                  <td>IDE</td>
                  <td>
                    Usado pelo Google DoubleClick para registrar e relatar as
                    ações do usuário do site após visualização ou clique em um
                    dos anúncios do anunciante com o objetivo de mensurar a
                    eficácia de um anúncio e apresentar anúncios direcionados ao
                    usuário.
                  </td>
                  <td>1 ano</td>
                </tr>
                <tr>
                  <td>pagead/1p-user-list/#</td>
                  <td>
                    Rastreia se o usuário demonstrou interesse em produtos
                    específicos ou eventos em vários sites e detecta como o
                    usuário navega entre os sites. Isso é usado para medir os
                    esforços de publicidade e facilita o pagamento das taxas de
                    referência apostadas entre sites.
                  </td>
                  <td>Sessão</td>
                </tr>
                <tr>
                  <td>tr</td>
                  <td>
                    Usado pelo Facebook para entregar uma série de produtos
                    publicitários, como lances em tempo real de terceiros
                    anunciantes.
                  </td>
                  <td>Sessão</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2>Período de armazenamento dos dados pessoais</h2>
          <p>
            Os dados coletados no site do Dimas Seguros serão armazenados: a)
            pelo tempo exigido por lei; b) até o término do tratamento dos dados
            pessoais, ou seja, quando do alcance da finalidade ou quando os
            dados deixarem de ser necessários ou pertinentes ao alcance da
            finalidade ou quando o titular estiver em seu direito de solicitar o
            término do tratamento e a exclusão de seus dados ou quando houver
            determinação legal nesse sentido; c) pelo tempo necessário a
            preservar o legítimo interesse das Empresas Dimas, conforme o caso;
            d) pelo tempo necessário para resguardar o exercício regular de
            direitos das Empresas Dimas.
          </p>
          <h2>Controlador dos dados pessoais</h2>
          <p>
            As Empresas Dimas serão o controlador de seus dados pessoais. Para a
            finalidade da legislação aplicável, controlador é a quem compete as
            decisões relativas ao tratamento de dados pessoais.
          </p>
          <h2>Encarregado dos dados pessoais</h2>
          <p>
            As Empresas Dimas nomeiam como Encarregado o Comitê de Segurança e
            Privacidade das Empresas Dimas que poderão ser contactados através
            do endereço de e-mail privacidade@dimas.com.br.
          </p>
          <h2>Compartilhamento dos seus dados pessoais</h2>
          <p>
            As Empresas Dimas poderão, excepcionalmente, compartilharem os seus
            dados pessoais com provedores de softwares e outras tecnologias da
            informação, para a finalidade de geração de cadastro e adoção de
            eventuais providências essenciais à operação das funcionalidades
            oferecidas no site destinadas à comunicação com o usuário, como:
          </p>
          <ul>
            <li>Desenvolvimento e codificação do site;</li>
            <li>
              Adoção de eventuais providências essenciais ao bom funcionamento
              das funcionalidades oferecidas no site;
            </li>
            <li>
              Disponibilizar a ferramenta de ChatBot para o envio de proposta de
              financiamento de veículo;
            </li>
            <li>
              Gerenciar os dados dos clientes interessados em nossos serviços e
              produtos para, eventualmente, contatá-los. No caso de candidatos a
              oportunidades de emprego, poderemos compartilhar seus dados com a
              plataforma Sólides, para a seleção de candidatos e realização de
              processo seletivo.
            </li>
          </ul>
          <h2>Direitos em relação aos seus dados pessoais</h2>
          <p>
            Você possui diversos direitos em relação aos seus dados pessoais, os
            quais as Empresas Dimas se comprometem em assegurar, dentre eles:
          </p>
          <ul>
            <li>A confirmação da existência de tratamento;</li>
            <li>O acesso aos dados;</li>
            <li>
              A correção de dados incompletos, inexatos ou desatualizados;
            </li>
            <li>
              A anonimização, bloqueio ou eliminação de dados desnecessários,
              excessivos ou tratados em desconformidade;
            </li>
            <li>
              A portabilidade a outro fornecedor de serviço ou produto, mediante
              requisição expressa;
            </li>
            <li>
              A informação das entidades públicas e privadas com as quais o
              controlador realizou o uso compartilhado de dados.
            </li>
          </ul>
          <h2>Armazenamento e segurança</h2>
          <p>
            As Empresas Dimas estão engajadas com a segurança dos seus dados
            pessoais. O Controlador adota todas as precauções para manter essa
            proteção, empregando sistemas de segurança e procedimentos adotados
            pelo mercado para proteger os seus dados.
          </p>
          <p>
            Cumpre ressaltar, ainda, que o controlador comunicará à autoridade
            nacional e ao titular a ocorrência de incidente de segurança que
            possa acarretar risco ou dano relevante aos titulares.
          </p>
          <h2>Dúvidas | Contato</h2>
          <p>
            Caso você entenda que seus dados foram utilizados de maneira
            incompatível com esta Política ou com as suas escolhas enquanto
            titular, ou, ainda, se você tiver outras dúvidas, comentários ou
            sugestões, entre em contato conosco através do endereço de e-mail
            privacidade@dimas.com.br.
          </p>
          <h2>Alterações</h2>
          <p>
            As Empresas Dimas reservam o direito de alterarem esta Política de
            Privacidade a qualquer momento, mediante publicação da versão
            atualizada nesta página, a fim de garantir que esta reflita o real
            tratamento de dados pessoais realizado por nós.
          </p>
          <p>
            A presente Política de Privacidade entra em vigor em 30 de outubro
            de 2024.
          </p>
        </section>
      </main>
      <Footer />
    </>
  )
}

export default PrivacityPolitic
